.datetime input{
    width: 100%;
    height: 56px;
    border-radius: 5px;
    background: #f4f6f8;
    border: 1px solid #d5dadf;
    padding:7px;
    font-size: 1rem;
}
.datetime input:hover{
    border:1px solid black;
}
.datetime input:focus{
    outline: #2065d1;
    border:2px solid #2065d1;
}


/* INPUT DATETIME DE LA TABLA */
.datetimeTable input{
    width: 220px;
    height: 56px;
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #d5dadf;
    padding:7px;
    font-size: 1rem;
}

.datetimeTable input:hover{
    border:1px solid black;
}

.datetimeTable input:focus{
    outline: #2065d1;
    border:2px solid #2065d1;
}

.link-back-map{
    border:none;
    text-decoration: none;
    color:#2065D1;
    display:flex;
}

.icon-back{
    height: 21px;
    width: 21px;
    margin-right:1px;
}

/* -------------------------------- MEDIA QUERYS --------- */


@media (max-width: 600px) {
    .modal-box {
      padding: 0;
    }



    
  }

/* 
.query-map-input {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.css-1b7b7jb-MuiButtonBase-root-MuiButton-root {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
} */